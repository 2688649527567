import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { AnchorLink } from "gatsby-plugin-anchor-links";

const Bedingungen = () => {

return (
  <Layout>
    <Seo title="Bedingungen" />
    <div className="container text-start my-5">
      <h1>Informationen zu unserem Service</h1>

      <p>Wir möchten Ihnen den Zugang zu den besten Deals für günstige Flüge anbieten.</p>

      <p class="mt-3">Wir durchsuchen Blogs, Magazine und Airlineangebote nach den besten Deals für Sie.</p>
      <ul class="no-bullets">
        <li><i class="bi bi-heart"></i> Wir durchforsten das Internet - Sie lehnen sich zurück</li>
        <li><i class="bi bi-heart"></i> Seriöse Quellen, zuverlässige Blogs und erfahrene Reiseexperten</li>
        <li><i class="bi bi-heart"></i> Mehrmals täglich aktualisiert</li>
      </ul>
      
      <p>Unser Service dient ausschliesslich der Übersicht über interessante Angabote fremder Anbieter.
        Es findet bei Budgettrips.de kein Direktverkauf oder Support statt. Alle Angaben sind ohne Gewähr. Für die Inhalte der Angebote sind die jeweiligen Anbieter verantwortlich.
        Genannte Marken- und Produktnamen sind Eigentum der entsprechenden Firmen.
      </p>
      <p>Falls Sie Anregungen haben, so freuen wir uns von Ihnen über das <AnchorLink to="/bedingungen/#budgettrips-kontakt" className="text-decoration-none">Kontaktformular</AnchorLink> zu hören.</p>
    </div>
  </Layout>
)}

export default Bedingungen
